import React, { useContext } from "react"

import ThemeContext from "../../components/context/themeContext"

const CloseMenuIcon = () => {
  const { isDarkThemeOn } = useContext(ThemeContext)
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.886961 0.152178C0.684056 -0.050726 0.355083 -0.0507261 0.152179 0.152178C-0.0507253 0.355083 -0.0507257 0.684055 0.152179 0.886959L6.76522 7.5L0.152178 14.113C-0.050726 14.3159 -0.0507261 14.6449 0.152178 14.8478C0.355083 15.0507 0.684055 15.0507 0.88696 14.8478L7.5 8.23478L14.113 14.8478C14.3159 15.0507 14.6449 15.0507 14.8478 14.8478C15.0507 14.6449 15.0507 14.3159 14.8478 14.113L8.23478 7.5L14.8478 0.886976C15.0507 0.684071 15.0507 0.355099 14.8478 0.152194C14.6449 -0.0507102 14.3159 -0.0507105 14.113 0.152194L7.5 6.76522L0.886961 0.152178Z"
        fill={isDarkThemeOn ? "white" : "#3E3E3E"}
      />
    </svg>
  )
}

export default CloseMenuIcon
