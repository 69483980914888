import React from "react"

import Styles from "../styles/button.module.scss"

const Button = ({ className, onClick, text, ...props }) => {
  return (
    <button
      tabIndex={0}
      className={`${Styles.button} ${className}`}
      onClick={onClick}
      {...props}
    >
      {text}
    </button>
  )
}

export default Button
