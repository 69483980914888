import React, { useState } from "react"

import Styles from "../styles/consentPopup.module.scss"
import Button from "./button"

const ConsentPopup = () => {
  const cookieConsent =
    typeof localStorage !== `undefined`
      ? localStorage.getItem("acceptedCookies") === "true"
      : true

  const [acceptedCookies, setAcceptedCookies] = useState(cookieConsent)

  const onCookieAccept = () => {
    setAcceptedCookies(true)
    if (typeof localStorage !== `undefined`) {
      localStorage.setItem("acceptedCookies", true)
    }
  }

  if (acceptedCookies) return null

  return (
    <div className={Styles.popupWrapper}>
      <span>
        By using this site you agree to the use of cookies for analytics
      </span>
      <Button
        className={Styles.button}
        onClick={onCookieAccept}
        text={"Got it"}
      />
    </div>
  )
}

export default ConsentPopup
