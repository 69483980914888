import React, { useContext, useState, useRef } from "react"

import Styles from "../styles/themeToggle.module.scss"
import ThemeContext from "./context/themeContext"
import Tooltip from "./tooltip"

const ThemeToggle = () => {
  const { isDarkThemeOn, toggleTheme } = useContext(ThemeContext)
  const [isHovering, setIsHovering] = useState(false)
  const toggle = useRef()

  if (isDarkThemeOn === undefined) {
    return null
  }

  const handleKeyPress = e => {
    if (e.key === "Enter") {
      toggleTheme()
    }
  }

  return (
    <>
      <Tooltip
        visible={isHovering}
        elementData={toggle.current && toggle.current.getBoundingClientRect()}
        text={isDarkThemeOn ? "Dark Theme Off" : "Dark Theme On"}
      />
      <div
        ref={toggle}
        role="button"
        tabIndex={0}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onKeyPress={handleKeyPress}
        onClick={() => toggleTheme()}
        className={Styles.toggleContainer}
        aria-label="toggle theme"
      >
        <div
          className={`${Styles.toggle} ${isDarkThemeOn ? Styles.darkMode : ""}`}
        ></div>
      </div>
    </>
  )
}

export default ThemeToggle
