import React, { useContext } from "react"

import styles from "../styles/footer.module.scss"
import GithubIcon from "../images/icons/githubIcon"
import LinkedInIcon from "../images/icons/linkedInIcon"
import InstagramIcon from "../images/icons/instagramIcon"
import ThemeContext from "./context/themeContext"

const Footer = () => {
  const { isDarkThemeOn } = useContext(ThemeContext)

  return (
    <footer className={`${styles.footer} ${isDarkThemeOn ? styles.dark : ""}`}>
      <div className={styles.verse}>
        <span>Do everything in love.</span>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.biblegateway.com/passage/?search=1%20Corinthians%2016:13-15&version=NIV"
          name="1 Corinthians 16:14"
        >
          1 Corinthians 16:14
        </a>
      </div>
      <div className={styles.socialLinks}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/daniellupascu"
          name="github"
        >
          <span className="hiddenText">github</span>
          <GithubIcon />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/lupascudaniel/"
          name="linkedin"
        >
          <span className="hiddenText">linkedin</span>
          <LinkedInIcon />
        </a>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/daniel_lupascu/"
          name="instagram"
        >
          <span className="hiddenText">instagram</span>
          <InstagramIcon />
        </a>
      </div>
      <div>
        © {new Date().getFullYear()} Built by Daniel Lupascu with
        {` `}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.gatsbyjs.org"
          name="gatsby"
        >
          Gatsby
        </a>
      </div>
    </footer>
  )
}

export default Footer
