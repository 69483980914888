import React from "react"
import Fade from "react-reveal/Fade"

import styles from "../styles/pageSection.module.scss"

const PageSection = ({ children, className, first }) => {
  return (
    <Fade delay={30}>
      <section
        className={`${className ? className : ""} ${styles.section} ${first &&
          styles.first}`}
      >
        {children}
      </section>
    </Fade>
  )
}

export default PageSection
