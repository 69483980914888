export const skillCategoryNames = {
  language: "Programming Languages",
  library_framework: "Libraries & Frameworks",
  tool_platform: "Tools & Platforms",
  design: "Design",
}

export const themeColors = {
  dark: {
    background: "#011936",
    primaryColor: "#8f96b1",
    textColor: "#fff",
    boxShadow: "0px 3px 10px -5px rgba(0, 0, 0, 0.70)",
    backdropBackgroundColor: "#00000042",
  },
  light: {
    background: "#fff",
    primaryColor: "#ffdb20",
    textColor: "#000",
    boxShadow: "0px 2px 10px 0px rgba(0, 0, 0, 0.05)",
    backdropBackgroundColor: "#e8e6e666",
  },
}

export const skillsColors = {
  frontendTheme: "#ee4266",
  backendTheme: "#9a2ec1",
  dataTheme: "#297373",
  platformTheme: "#3a86ff",
  designTheme: "#ff8552",
  otherTheme: "#f49097",
}
