import React from "react"
import ReactDOM from "react-dom"

import Styles from "../styles/tooltip.module.scss"

const Tooltip = ({ visible, elementData, text }) => {
  const portal =
    typeof document !== `undefined`
      ? document.querySelector("#root-portal-container")
      : null
  const el =
    typeof document !== `undefined` ? document.createElement("div") : null
  let tooltip
  if (elementData) {
    const style = {
      top: elementData.bottom + 10,
      left: elementData.left + elementData.width / 2,
    }
    tooltip = visible && (
      <div style={style} className={Styles.tooltip}>
        {text}
      </div>
    )
  }

  if (el) {
    return ReactDOM.createPortal(tooltip, portal)
  } else {
    return null
  }
}

export default Tooltip
