/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"
import MainStyles from "../styles/main.module.scss"
import Footer from "./footer"
import ThemeContext from "./context/themeContext"
import { themeColors } from "../constants"
import ConsentPopup from "./consentPopup"
import ContactSection from "./contactSection"
import { Helmet } from "react-helmet"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [isDarkThemeOn, rawSetIsDarkThemeOn] = useState(undefined)

  useEffect(() => {
    const root = window.document.documentElement
    const initialIsDarkThemeOn = root.style.getPropertyValue(
      "--is-dark-theme-on"
    )
    rawSetIsDarkThemeOn(initialIsDarkThemeOn === "true")
  }, [])

  const setIsDarkThemeOn = () => {
    const updatedIsDarkThemeOn = !isDarkThemeOn

    rawSetIsDarkThemeOn(updatedIsDarkThemeOn)

    window.localStorage.setItem("isDarkThemeOn", updatedIsDarkThemeOn)

    const root = document.documentElement
    root.style.setProperty("--bodyTransitionTime", "0.4s") // add animation to the body color
    root.style.setProperty("--is-dark-theme-on", updatedIsDarkThemeOn)
    root.style.setProperty(
      "--primaryColor",
      updatedIsDarkThemeOn
        ? themeColors.dark.primaryColor
        : themeColors.light.primaryColor
    )
    root.style.setProperty(
      "--backgroundColor",
      updatedIsDarkThemeOn
        ? themeColors.dark.background
        : themeColors.light.background
    )
    root.style.setProperty(
      "--textColor",
      updatedIsDarkThemeOn
        ? themeColors.dark.textColor
        : themeColors.light.textColor
    )
    root.style.setProperty(
      "--backdropBackgroundColor",
      updatedIsDarkThemeOn
        ? themeColors.dark.backdropBackgroundColor
        : themeColors.light.backdropBackgroundColor
    )
    root.style.setProperty(
      "--boxShadow",
      updatedIsDarkThemeOn
        ? themeColors.dark.boxShadow
        : themeColors.light.boxShadow
    )
  }

  return (
    <ThemeContext.Provider
      value={{
        isDarkThemeOn,
        toggleTheme: () => setIsDarkThemeOn(),
      }}
    >
      <Helmet>
        {/* <!-- Global site tag (gtag.js) - Google Analytics --> */}
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-167913319-1"
        ></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || []; function gtag()
          {dataLayer.push(arguments)}
          gtag('js', new Date()); gtag('config', 'UA-167913319-1');
          `}
        </script>
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main className={MainStyles.main}>{children}</main>
      <ContactSection />
      <Footer />
      <ConsentPopup />
    </ThemeContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
