import { Link } from "gatsby"
import React, { useState, useEffect, useContext, useRef } from "react"

import headerStyle from "../styles/header.module.scss"
import CloseMenuIcon from "../images/icons/closeMenu"
import OpenMenuIcon from "../images/icons/openMenu"
import ThemeToggle from "./themeToggle"
import ThemeContext from "./context/themeContext"

const Header = () => {
  const [isMenuExpanded, setIsMenuExpanded] = useState(false)
  // const [firstScreenTouch, setFirstScreenTouch] = useState(null)
  const [isNavStuck, setIsNavStuck] = useState(false)

  const navRef = useRef(null)

  const { isDarkThemeOn } = useContext(ThemeContext)

  const onSwipe = e => {
    // disable swiping
    // function that toggles the menu when swiping left or right
    // if (!firstScreenTouch) {
    //   setFirstScreenTouch(e.touches[0])
    // }
    // window.clearTimeout(isSwiping) // eslint-disable-line no-use-before-define
    // const isSwiping = setTimeout(function() {
    //   // when swiping ends
    //   let isSwipingRight = false
    //   let isSwipingLeft = false
    //   if (firstScreenTouch) {
    //     isSwipingRight = e.touches[0].clientX - firstScreenTouch.clientX > 100
    //     isSwipingLeft = e.touches[0].clientX - firstScreenTouch.clientX < -100
    //   }
    //   if (isSwipingRight) {
    //     setIsMenuExpanded(true)
    //     setFirstScreenTouch(null)
    //   }
    //   if (isSwipingLeft) {
    //     setIsMenuExpanded(false)
    //     setFirstScreenTouch(null)
    //   }
    // }, 100)
  }

  const onScroll = e => {
    if (window.scrollY > 39) {
      setIsNavStuck(true)
    } else {
      setIsNavStuck(false)
    }
  }

  const handleMenuToggleKeyPress = (shouldOpenMenu, e) => {
    if (e.key === "Enter") {
      // open menu on Enter
      setIsMenuExpanded(shouldOpenMenu)
    }
  }

  useEffect(() => {
    window.addEventListener("touchmove", onSwipe)
    window.addEventListener("scroll", onScroll)
    return () => {
      window.removeEventListener("touchmove", onSwipe)
      window.removeEventListener("scroll", onScroll)
    }
  })

  const url = typeof window !== "undefined" ? window.location.href : ""
  const regex = /\w+$/g // mathces the last word of the url
  const isHomePage = !url.match(regex)?.length

  return (
    <header
      className={`${headerStyle.header} ${
        isDarkThemeOn ? headerStyle.darkHeader : ""
      }`}
    >
      <div
        role="button"
        aria-label="show menu"
        tabIndex={0}
        className={headerStyle.openMenuIcon}
        onKeyPress={handleMenuToggleKeyPress.bind(this, true)}
        onClick={() => setIsMenuExpanded(true)}
      >
        <OpenMenuIcon />
      </div>
      <nav
        ref={navRef}
        className={`${headerStyle.navigation} ${
          isMenuExpanded ? headerStyle.expandedNav : ""
        } ${isNavStuck ? headerStyle.stuck : ""}
          ${isHomePage ? headerStyle.withShadow : ""}`}
      >
        <div
          role="button"
          aria-label="close menu"
          tabIndex={0}
          className={headerStyle.closeMenuIcon}
          onKeyPress={handleMenuToggleKeyPress.bind(this, false)}
          onClick={() => setIsMenuExpanded(false)}
        >
          <CloseMenuIcon />
        </div>

        <div className={headerStyle.menuContainer}>
          <div className={headerStyle.menuItem}>
            <Link to="/" activeClassName={headerStyle.active}>
              <div className={headerStyle.buttonText}>About</div>
              <div className={headerStyle.activeIndicator}></div>
            </Link>
          </div>
          <div className={headerStyle.menuItem}>
            <Link to="/experience" activeClassName={headerStyle.active}>
              <div className={headerStyle.buttonText}>Experience</div>
              <div className={headerStyle.activeIndicator}></div>
            </Link>
          </div>
          {/* <div className={headerStyle.menuItem}>
            <Link to="/resume" activeClassName={headerStyle.active}>
              <div
                role="button"
                tabIndex={0}
                className={headerStyle.buttonText}
              >
                Resume
              </div>
              <div className={headerStyle.activeIndicator}></div>
            </Link>
          </div> */}
          <div className={headerStyle.menuItem}>
            <Link to="/contact" activeClassName={headerStyle.active}>
              <div
                role="button"
                tabIndex={0}
                className={headerStyle.buttonText}
              >
                Get in touch
              </div>
              <div className={headerStyle.activeIndicator}></div>
            </Link>
          </div>
        </div>
        <div className={headerStyle.themeToggle}>
          <ThemeToggle />
        </div>
      </nav>
    </header>
  )
}

export default Header
