import React from "react"
import { Link } from "gatsby"

import PageSection from "./pageSection"

import Styles from "./../styles/contactSection.module.scss"

const ContactSection = () => {
  return (
    <PageSection className={Styles.section}>
      <div className={Styles.question}>
        <span>Have a great project idea?</span>

        <Link to="/contact">
          <div role="button" tabIndex={0}>
            Get in touch
          </div>
        </Link>
      </div>
    </PageSection>
  )
}

export default ContactSection
