import React, { useContext } from "react"

import ThemeContext from "../../components/context/themeContext"

const OpenMenuIcon = () => {
  const { isDarkThemeOn } = useContext(ThemeContext)

  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.5C0 0.223858 0.223858 0 0.5 0H17.5C17.7761 0 18 0.223858 18 0.5C18 0.776142 17.7761 1 17.5 1H0.5C0.223858 1 0 0.776142 0 0.5ZM0 14.5C0 14.2239 0.223858 14 0.5 14H17.5C17.7761 14 18 14.2239 18 14.5C18 14.7761 17.7761 15 17.5 15H0.5C0.223858 15 0 14.7761 0 14.5ZM0.5 7C0.223858 7 0 7.22386 0 7.5C0 7.77614 0.223857 8 0.5 8H12.1C12.3761 8 12.6 7.77614 12.6 7.5C12.6 7.22386 12.3761 7 12.1 7H0.5Z"
        fill={isDarkThemeOn ? "white" : "#3E3E3E"}
      />
    </svg>
  )
}
export default OpenMenuIcon
